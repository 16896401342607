import { Layout, SEO } from 'components/common'
import Container from 'components/common/Container'
import { Footer } from 'components/theme'
import Head from 'components/theme/Head'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

const Thanks = () => {
  const isBrowser = () => typeof window !== 'undefined'
  const urlSearchParams = isBrowser() && new URLSearchParams(window.location.search)
  const params = isBrowser() && Object.fromEntries(urlSearchParams.entries())
  const { fname, pname } = params
  const seoTitle = `Thank you ${pname || fname}`
  return (
    <>
      <Layout>
        <SEO title={seoTitle} />
        <Head />
        <main>
          <div id="thanks-page">
            <div className="hero">
              <StaticImage src="../assets/du-thanks-page.png" alt="Denver Campus Shot" />
            </div>
            <Container constraints="narrow">
              <div className="intro-thanks">
                {pname || fname ? (
                  <h1>Dear {pname || fname}, thank you for your interest in the University of Denver. </h1>
                ) : (
                  <h1>Thank you for your interest in the University of Denver.</h1>
                )}
                <p>
                  We are excited that you are considering DU and all the experiences it has to offer, including study
                  abroad, close faculty interaction, interterm courses (many offered during our six-week winter break!)
                  and a beautiful location in the city of Denver.
                </p>

                <p>
                  Choosing a college or university is a very important decision and we’d love to help you determine if
                  DU will be the best fit. If you have questions about a particular major, if you are curious about
                  student life on campus, or if you need someone to walk you through your next steps, don’t hesitate to
                  reach out to your admission counselor.{' '}
                </p>

                <p>
                  Our Facebook page and videos will give you an idea of what it is like to be a DU Pioneer. Also, feel
                  free to check out our blogs to hear directly from our current students and ask them questions about
                  life on campus.
                </p>

                <p>
                  If you have not yet experienced DU for yourself, please consider visiting in the upcoming months. We
                  look forward to seeing you soon!
                </p>

                <p className="thanksBy">Undergraduate Admission</p>
              </div>
            </Container>
            <section
              className="
          wrapper
        section important-dates
        "
            >
              <div className="container">
                <div className="headline">
                  <h2>IMPORTANT UPCOMING DATES AND DEADLINES</h2>
                </div>
                <div className="text-section">
                  <h2>June 1</h2>
                  <p>Fall Transfer Deadline</p>
                </div>
              </div>
            </section>

            <section
              className="
          wrapper centered
        video-section
        "
            >
              <div className="container">
                <div className="row row-two-column  css-lrqkzw">
                  <div className="first-column css-11t2kt2">
                    <div className="text-section">
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/1WpZK8C87Sw"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen=""
                      />
                    </div>
                  </div>
                  <div className="second-column css-11t2kt2">
                    <div className="text-section">
                      <p>
                        We’re a community built on action. Here, we strive to inspire positive change, at home and
                        around the world. <strong>Discover DU - for the difference.</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </Layout>
      <Footer />
    </>
  )
}

export default Thanks
